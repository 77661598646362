.hiddenSurveyElement {
  transition: all 1s ease-in-out;
  transform-origin: left top;
  transform: scaleY(0);
}

.visibleSurveyElement {
  transform: scaleY(1);
}

.sv_main button,
.sv_main .sv_nav .sv_complete_btn,
.sv_main .sv_nav .sv_next_btn,
.sv_main .sv_nav .sv_prev_btn {
  background-color: #f84d17 !important;
}

div.sd-title.sd-container-modern__title > div.sd-header__text h3 {
  font-size: 2rem;
  text-align: center;
}

/* Adjust the size for medium screens */
@media screen and (max-width: 768px) {
  div.sd-title.sd-container-modern__title > div.sd-header__text h3 {
    font-size: 1.5rem;
  }
}

/* Adjust the size and text for small screens */
@media screen and (max-width: 480px) {
  div.sd-title.sd-container-modern__title > div.sd-header__text h3 {
    font-size: 1rem;
    content: "Take Our Skin Quiz";
  }
}

#sv-nav-complete > div > input:hover{
  background-color: #f84d17 !important;
}

.survey-container {
  width: 100%;
}

.survey-content {
  width: 80%;
  margin: auto;
}

/* .survey-title {
  text-align: center;
  border-bottom: none !important;
} */

.center-title {
  text-align: center !important;
}

.remove-border {
  border-bottom: none !important;
}
