@tailwind base;
@tailwind components;
@tailwind utilities;

/* fonts */
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  scroll-behavior: smooth;
}
body {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  background: #f6f6f5;
  scroll-behavior: smooth;
}
input,

select {
  outline: none;
}

.batch_heart {
  background: tomato;
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  position: absolute;
  right: -24%;
  top: -9%;
  cursor: pointer;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

iframe#webpack-dev-server-client-overlay{display:none!important}

.before-after-slider {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.column {
  flex: 1;
  text-align: center;
}

.image-container {
  position: relative;
  overflow: hidden;
}

.image {
  max-width: 100%;
  height: auto;
  transition: transform 0.3s ease-in-out;
}

.slider-input {
  width: 100%;
}

/* Media Query for Screens Below MD (Medium) */
@media (max-width: 768px) {
  .before-after-slider {
    flex-direction: column;
  }

  .column {
    text-align: center;
  }
}



.sv_main button,
.sv_main .sv_nav .sv_complete_btn,
.sv_main .sv_nav .sv_next_btn,
.sv_main .sv_nav .sv_prev_btn {
  background-color: #f84d17 !important;
}
* {
  margin: 0px;
  box-sizing: border-box;
}


